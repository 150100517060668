<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div>
          <h1>Registro Nómina</h1>
          <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-6">
              <label for="name">Empresa</label>
              <Dropdown
                  name="nombre"
                  v-model="empresa"
                  :options="empresas"
                  optionLabel="nombre"
                  placeholder="Seleccione una Empresa"
                  filter
                  @change="filtrar"
                  showClear
                  reset-filter-on-hide
              />
            </div>
            <div class="field col-12 md:col-6">
                  <label for="name">Año</label>
                  <Dropdown
                      name="nombre"
                      v-model="periodo.anio"
                      :options="anios"
                      placeholder="Seleccione un Año"
                      filter
                      :disabled="disabledAnios"
                      @change="handlePeriodo"
                      showClear
                      reset-filter-on-hide
                  />
                  <hr/>
                  <label for="municipio">Mes</label>
                  <Dropdown
                      name="nombre"
                      v-model="mes"
                      :options="periodos"
                      optionLabel="nombreMes"
                      :disabled="enablePeriodos"
                      placeholder="Seleccione un Mes"
                      showClear
                      @change="filtrar"
                      filter
                      reset-filter-on-hide
                  />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <DataTable :value="registros" v-model:expandedRows="expandedRows" dataKey="id"
                   :paginator="true" :rows="10"
                   stripedRows
                   :sort-field="'mes'"
                   :sort-order="1"
                   removableSort
                   :emptyMessage="'No hay datos para mostrar'"
                   v-model:filters="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                   :rowsPerPageOptions="[5,10,15,25]">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gestionar Empresas</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"/>
                 <InputText v-model="filters['global'].value" placeholder="Search..."/>
              </span>
            </div>
          </template>
          <Column :expander="true" headerStyle="min-width: 3rem"/>
          <Column field="nombreMes" header="Periodo" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ slotProps.data.nombreMes }} - {{slotProps.data.anio}}
              </div>
            </template>
          </Column>
          <Column field="empleado" style="width:15%" header="Empleado" :sortable="true"/>
          <Column field="cc" style="width:10%" header="Identificación" :sortable="true"/>
          <Column field="diasTrabajados" style="{width:5%}" header="Dias" :sortable="true"/>
          <Column field="salario" style="width:10%" header="Salario" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.salario) }}
              </div>
            </template>
          </Column>
          <Column field="auxilio" style="width:10%" header="Auxilio" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.auxilio) }}
              </div>
            </template>
          </Column>
          <Column field="desalarizado" style="width:10%" header="Desalarizado" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.desalarizado) }}
              </div>
            </template>
          </Column>
          <Column field="total" style="width:10%" header="Total" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.total) }}
              </div>
            </template>
          </Column>
          <Column field="totalProvision" style="width:10%" header="Provision" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.totalProvision) }}
              </div>
            </template>
          </Column>
          <Column field="totalNomina" style="width:10%" header="Total Nomina" :sortable="true">
            <template #body="data">
              <div>
                {{ formatCurrency(data.data.totalNomina) }}
              </div>
            </template>
          </Column>
          <Column :exportable="true" style="min-width:8rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                      @click="edit(slotProps.data.id)"/>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="p-3 ">
              <div class="p-fluid formgrid grid">
                <div class="col-12 md:col-6">
                  <Fieldset legend="Pensión" :toggleable="true" v-model:collapsed="isCollapsedL">
                    <div class="field col-12 md:col-12">
                      <label for="x">Aporte Empresa</label>
                      <InputText :value="slotProps.data.aporteEmpresa" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="y">Fondo Solidaridad</label>
                      <InputText id="y" :value="slotProps.data.fondoSolidaridad" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="y">Descuento Trabajador</label>
                      <InputText id="y" :value="slotProps.data.descuentoTrabajador" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="y">Total Aporte Pension</label>
                      <InputText id="y" :value="slotProps.data.totalAportePension" type="text" disabled/>
                    </div>
                  </Fieldset>
                </div>
                <div class="col-12 md:col-6">
                  <Fieldset legend="Aportes Parafiscales" :toggleable="true" v-model:collapsed="isCollapsed">
                    <div class="field col-12 md:col-12">
                      <label for="inic">Comfaboy</label>
                      <InputText id="inic" v-model="slotProps.data.comfaboy" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">I.C.B.F</label>
                      <InputText id="consc" v-model="slotProps.data.icbf" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Sena</label>
                      <InputText id="consc" v-model="slotProps.data.sena" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Total</label>
                      <InputText id="consc" v-model="slotProps.data.totalAportesParafiscales" type="text" disabled/>
                    </div>
                  </Fieldset>
                </div>
                <div class="col-12 md:col-6">
                  <Fieldset legend="Prestaciones Sociales" :toggleable="true" v-model:collapsed="isCollapsed">
                    <div class="field col-12 md:col-12">
                      <label for="inic">Cesantias</label>
                      <InputText id="inic" v-model="slotProps.data.cesantias" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Interes</label>
                      <InputText id="consc" v-model="slotProps.data.interes" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Prima</label>
                      <InputText id="consc" v-model="slotProps.data.prima" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Vacaciones</label>
                      <InputText id="consc" v-model="slotProps.data.vacaciones" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Total</label>
                      <InputText id="consc" v-model="slotProps.data.totalPrestacionesSociales" type="text" disabled/>
                    </div>
                  </Fieldset>
                </div>
                <div class="col-12 md:col-6">
                  <Fieldset legend="Aportes Sociales" :toggleable="true" v-model:collapsed="isCollapsed">
                    <div class="field col-12 md:col-12">
                      <label for="inic">Total Deducción Trabajador</label>
                      <InputText id="inic" v-model="slotProps.data.totalDeduccionTrabajador" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Salud</label>
                      <InputText id="consc" v-model="slotProps.data.salud" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">ARP</label>
                      <InputText id="consc" v-model="slotProps.data.arp" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Total Aporte Empresa</label>
                      <InputText id="consc" v-model="slotProps.data.totalAporteEmpresa" type="text" disabled/>
                    </div>
                    <div class="field col-12 md:col-12">
                      <label for="consc">Total Salario a Pagar</label>
                      <InputText id="consc" v-model="slotProps.data.totalSalarioAPagar" type="text" disabled/>
                    </div>
                  </Fieldset>
                </div>
              </div>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="estudioDialog" :style="{ width: '1000px' }" header="Detalle Empleado" :modal="true"
          class="p-fluid">
    <SalarioForm :selected="registro" :editar="true" @cerrar-modal="cerrarModal"></SalarioForm>
  </Dialog>
</template>

<script>
import apiService from "@/service/api.service";
import SalarioForm from "@/components/SalarioForm.vue";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "ListarEstudios",
  components: {SalarioForm},
  data() {
    return {
      enablePeriodos: true,
      anios:[],
      anio:{},
      mes:{},
      registros: [],
      expandedRows: [],
      registro: {},
      empresas: [],
      periodos:[],
      periodo:{},
      empresa: {},
      filters: {},
      dt: [],
      isCollapsed: true,
      isCollapsedL: true,
      estudioDialog: false,
      disabledAnios:true,
    }
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0
      }).format(value);
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    },
    cerrarModal() {
      this.obtenerLista();
      this.estudioDialog = false;
    },
    handlePeriodo() {
      if (this.periodo.anio !== null) {
        this.enablePeriodos = false;
        this.filtrar();
        this.obtenerPeriodos()
      } else {
        this.enablePeriodos = true;
        this.obtenerLista();
      }
    },
    obtenerSalario(id) {
      return apiService.getSalario(id).then(
          (response) => {
            this.registro = response.data;
            return response;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    edit(editCliente) {
      return new Promise((resolve, reject) => {
        this.obtenerSalario(editCliente).then(
            (response) => {
              this.estudioDialog = true;
              resolve(response);
            },
            (error) => {
              reject(error);
            }
        );
      });
    },
    obtenerPeriodos() {
      return apiService.getPeriodosAnio(this.periodo.anio).then(
          (response) => {
            this.periodos = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerEmpresas() {
      return apiService.getEmpresas().then(
          (response) => {
            this.empresas = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerAnios() {
      return apiService.getPeriodosAnios().then(
          (response) => {
            this.anios = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    filtrar(){

      if(this.empresa !== null){
        this.disabledAnios = false;
      }else{
        this.disabledAnios = true;
        this.registros = [];
      }
      this.obtenerLista();
    },
    obtenerLista() {
      if(this.mes !== null){
      this.periodo.mes = this.mes.mes;
      }
      else{
        this.periodo.mes = null;
      }
      return apiService.getSalarios(this.empresa,this.periodo).then(
          (response) => {
            this.registros = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.registros=[];
          }
      );
    },
  },
  beforeMount() {
    this.initFilters();
  },
  mounted() {
    this.obtenerAnios();
    this.obtenerEmpresas()
  }

}

</script>




import interceptor from "@/service/interceptor";

const API_URL = process.env.VUE_APP_API_URL;

class ApiService {
    getEmpleados() {
        return interceptor.get(API_URL + 'empleados');
    }
    getEmpleadosEmpresa(empresa) {
        return interceptor.post(API_URL + 'empleados/empresa', empresa)
    }
    newEmpleado(empleado) {
        return interceptor.post(API_URL + 'empleados/crear', empleado)
    }

    actualizarEmpleado(empleado) {
        return interceptor.post(API_URL + 'empleados/actualizar', empleado)
    }

    borrarEmpleado(empleado) {
        return interceptor.post(API_URL + 'empleados/borrar', empleado)
    }
    borrarListaEmpleados(empleado) {
        return interceptor.post(API_URL + 'empleados/borrar-lista', empleado)
    }

    getEmpresas() {
        return interceptor.get(API_URL + 'empresas');
    }

    newEmpresa(empresa) {
        return interceptor.post(API_URL + 'empresas/crear', empresa)
    }

    actualizarEmpresa(empresa) {
        return interceptor.post(API_URL + 'empresas/actualizar', empresa)
    }

    borrarEmpresa(empresa) {
        return interceptor.post(API_URL + 'empresas/borrar', empresa)
    }
    borrarListaEmpresas(empresa) {
        return interceptor.post(API_URL + 'empresas/borrar-lista', empresa)
    }

    newSalario(salario) {
        return interceptor.post(API_URL + 'base/crear', salario)
    }

    newSalarioPeriodo(salario) {
        return interceptor.post(API_URL + 'salarios/crear', salario)
    }
    getSalarios(empresa,periodo) {

        return interceptor.post(API_URL + 'salarios',{ empresa,periodo });
    }

    editarSalario(salario) {
        return interceptor.post(API_URL + 'salarios/editar', salario)
    }
    getSalariosReporte(empresa,periodo) {
        return interceptor.post(API_URL + 'salarios/reporte',{ empresa,periodo });
    }
    getSalario(id) {
        return interceptor.get(API_URL + 'salarios/salario?id='+id);
    }

    getBase() {
        return interceptor.get(API_URL + 'base');
    }

    editarBase(base) {
        return interceptor.post(API_URL + 'base/editar', base)
    }

    getPeriodosAnio(periodo){
        const data ={
            anio:periodo
        }
        return interceptor.post(API_URL + 'periodos/periodo', data);
    }
    getPeriodos(){
        return interceptor.get(API_URL + 'periodos');
    }
    getPeriodosAnios() {
        return interceptor.get(API_URL + 'periodos/get');
    }
    uploadFile(file,empresa,anio) {
        const data = new FormData();
        data.append('file', file)
        data.append('empresa',empresa)
        data.append('anio',anio)
        return interceptor.post(API_URL + 'upload', data)
    }
}

export default new ApiService();

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h1> Base Salarial </h1>
        <Toast/>
        <DatosSalarioForm/>
      </div>
    </div>
  </div>
</template>

<script>


import DatosSalarioForm from "@/components/BaseForm.vue";

export default {
  components: {DatosSalarioForm}
}
;
</script>

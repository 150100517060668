<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img :src="logoPath" alt="logo"/>
      <span>Nominas</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onUserClick">
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <button class="layout-topbar-button p-link" type="button" @click="onSettingsClick">
        <i class="pi pi-cog"></i>
      </button>
      <button @click="onUserClick" class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span>Profile</span>
      </button>
    </div>
  </div>

  <Sidebar v-model:visible="visibleRight" :header="'Hola,'" position="right">
    <Button @click="handleLogout">Cerrar Sesión</Button>
  </Sidebar>
  <Sidebar v-model:visible="isVisible" position="right" class="layout-topbar-sidebar w-26rem">
      <div class="p-2">
        <section class="pb-4 flex align-items-center justify-content-between border-bottom-1 surface-border">
          <span class="text-xl font-semibold">Scale</span>
          <div class="flex align-items-center gap-2 border-1 surface-border py-1 px-2" style="border-radius: 30px">
            <Button icon="pi pi-minus" @click="decrementScale" text rounded
                    :disabled="layoutConfig.scale === scales[0]"/>
            <i v-for="s in scales" :key="s"
               :class="['pi pi-circle-fill text-sm text-200', { 'text-lg text-primary': s === layoutConfig.scale }]"/>

            <Button icon="pi pi-plus" @click="incrementScale" text rounded
                    :disabled="layoutConfig.scale === scales[scales.length - 1]"/>
          </div>
        </section>

        <section class="py-4 flex align-items-center justify-content-between border-bottom-1 surface-border">
          <span :class="['text-xl font-semibold']">Dark Mode</span>
          <InputSwitch :modelValue="layoutConfig.darkTheme" @update:modelValue="onDarkModeChange"/>
        </section>

        <template v-if="!simple">
          <section class="py-4 flex align-items-center justify-content-between border-bottom-1 surface-border">
            <span class="text-xl font-semibold">Menu Type</span>
            <SelectButton :modelValue="layoutConfig.menuMode" @update:modelValue="onMenuModeChange"
                          :options="menuModes" optionLabel="label" optionValue="value" :allowEmpty="false"/>
          </section>

        </template>

        <section class="py-4 border-bottom-1 surface-border">
          <div class="text-xl font-semibold mb-3">Themes</div>
          <div class="flex align-items-center justify-content-between gap-3 mb-3">
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'green'), 'hover:border-500 surface-border': !isThemeActive('aura', 'green') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'green')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #4dac9c 0%, rgba(77, 172, 156, 0.5) 100%)"></span>
            </button>
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'cyan'), 'hover:border-500 surface-border': !isThemeActive('aura', 'cyan') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'cyan')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #06b6d4 0%, rgba(6, 182, 212, 0.5) 100%)"></span>
            </button>
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'blue'), 'hover:border-500 surface-border': !isThemeActive('aura', 'blue') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'blue')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #4378e6 0%, rgba(67, 120, 230, 0.5) 100%)"></span>
            </button>
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'indigo'), 'hover:border-500 surface-border': !isThemeActive('aura', 'indigo') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'indigo')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #585fe0 0%, rgba(88, 95, 224, 0.5) 100%)"></span>
            </button>
          </div>
          <div class="flex align-items-center justify-content-between gap-3 mb-3">
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'purple'), 'hover:border-500 surface-border': !isThemeActive('aura', 'purple') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'purple')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #7758e4 0%, rgba(119, 88, 228, 0.5) 100%)"></span>
            </button>
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'amber'), 'hover:border-500 surface-border': !isThemeActive('aura', 'amber') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'amber')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #f59e0b 0%, rgba(245, 158, 11, 0.5) 100%)"></span>
            </button>
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'teal'), 'hover:border-500 surface-border': !isThemeActive('aura', 'teal') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'teal')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #14b8a6 0%, rgba(20, 184, 166, 0.5) 100%)"></span>
            </button>
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'pink'), 'hover:border-500 surface-border': !isThemeActive('aura', 'pink') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'pink')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #ec4899 0%, rgba(236, 72, 153, 0.5) 100%)"></span>
            </button>
          </div>
          <div class="flex align-items-center justify-content-between gap-3">
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'noir'), 'hover:border-500 surface-border': !isThemeActive('aura', 'noir') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'noir')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #0f172a 0%, rgba(0, 0, 0, 0.5) 100%)"></span>
            </button>
            <button
                :class="[
                            'bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200',
                            { 'border-primary': isThemeActive('aura', 'lime'), 'hover:border-500 surface-border': !isThemeActive('aura', 'lime') }
                        ]"
                style="border-radius: 30px"
                @click="changeTheme('aura', 'lime')"
            >
            <span class="block h-1rem w-full"
                  style="border-radius: 30px; background: linear-gradient(180deg, #84cc16 0%, rgb(132, 204, 22, 0.5) 100%)"></span>
            </button>
            <span class="w-3"></span>
            <span class="w-3"></span>
          </div>
        </section>
      </div>
  </Sidebar>

<!--  <AppConfig :visible="isVisible" @sidebarClosed="handleSidebarClosed"/>-->
</template>

<script>
import {useLayout} from '@/components/composables/layout';
import {mapActions} from "vuex";

import {usePrimeVue} from "primevue/config";

export default {
  components: {},
  data() {
    return {
      outsideClickListener: null,
      topbarMenuActive: false,
      visibleRight: false,
      layoutConfig: null,
      simple:false,
      isVisible: false,
      $primevue: usePrimeVue(),
      scales: [12, 13, 14, 15, 16, 17, 18, 19, 20],
      visible: false,
      menuModes: [
        {label: 'Static', value: 'static'},
        {label: 'Overlay', value: 'overlay'}
      ],
      compactMaterial: false
      // layoutConfig: useLayout().layoutConfig,
    };
  },
  computed: {
    topbarMenuClasses() {
      return {
        'layout-topbar-menu-mobile-active': this.topbarMenuActive
      };
    },
    logoPath() {
      if (this.layoutConfig && this.layoutConfig.darkTheme) {
        return require('../../assets/images/blanco.png');
      } else {
        return require('../../assets/images/blanco.png');
      }
    },
  },
  methods: {
    ...mapActions(['logout']),
    handleLogout() {
      this.logout().then(() => {
        this.$router.push('/auth/login');
      }).catch((error) => {
        this.$toast.add({severity: 'error', summary: 'Error de Comunicacion', detail: error, life: 5000});

      })
    },
    onTopBarMenuButton() {
      this.topbarMenuActive = !this.topbarMenuActive;
    },
    handleSidebarClosed() {
      this.isVisible = this.visible
    },
    onSettingsClick() {
      this.isVisible = true;
    },
    onUserClick() {
      this.visibleRight = true;
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (this.isOutsideClicked(event)) {
            this.topbarMenuActive = false;
          }
        };
        document.addEventListener('click', this.outsideClickListener);
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.removeEventListener('click', this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },
    isOutsideClicked(event) {
      if (!this.topbarMenuActive) return;

      const sidebarEl = document.querySelector('.layout-topbar-menu');
      const topbarEl = document.querySelector('.layout-topbar-menu-button');
      return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
    },
    onChangeTheme(theme, mode) {
      this.$primevue.changeTheme(this.layoutConfig.theme, theme, 'theme-css', () => {
        this.layoutConfig.theme = theme;
        this.layoutConfig.darkTheme = mode;
      });
    },
    decrementScale() {
      this.setScale(this.layoutConfig.scale - 1);
      this.applyScale();
    },
    incrementScale() {
      this.setScale(this.layoutConfig.scale + 1);
      this.applyScale();
    },
    applyScale() {
      document.documentElement.style.fontSize = this.layoutConfig.scale + 'px';
    },
    onMenuModeChange(value) {
      this.layoutConfig.menuMode = value;
    },
    onDarkModeChange(value) {
      const newThemeName = value ? this.layoutConfig.theme.replace('light', 'dark') : this.layoutConfig.theme.replace('dark', 'light');
      this.layoutConfig.darkTheme = value;
      this.onChangeTheme(newThemeName, value);
    },
    changeTheme(theme, color) {
      let newTheme, dark;
      newTheme = theme + '-' + (this.layoutConfig.darkTheme ? 'dark' : 'light');
      if (color) {
        newTheme += '-' + color;
      }
      if (newTheme.startsWith('md-') && this.compactMaterial) {
        newTheme = newTheme.replace('md-', 'mdc-');
      }
      dark = this.layoutConfig.darkTheme;
      this.onChangeTheme(newTheme, dark);
    },
    isThemeActive(themeFamily, color) {
      let themeName;
      let themePrefix = themeFamily === 'md' && this.compactMaterial ? 'mdc' : themeFamily;

      themeName = themePrefix + (this.layoutConfig.darkTheme ? '-dark' : '-light');

      if (color) {
        themeName += '-' + color;
      }

      return this.layoutConfig.theme === themeName;
    },

    setScale(value) {
      this.layoutConfig.scale = value;
    },
    onCloseSidebar() {
      this.$emit('sidebarClosed'); // Emitir el evento 'sidebarClosed'
    }
  },
  mounted() {
    this.layoutConfig = useLayout().layoutConfig;
    this.onMenuToggle = useLayout().onMenuToggle;
    this.applyScale();
    this.bindOutsideClickListener();
  },
  beforeUnmount() {
    this.unbindOutsideClickListener();
  }
};
</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h1> Reporte Nómina </h1>
        <div class="grid p-fluid mt-3">
          <div class="field col-12 md:col-6">
            <label for="name">Empresa</label>
            <Dropdown
                name="nombre"
                v-model="empresa"
                :options="empresas"
                optionLabel="nombre"
                placeholder="Seleccione una Empresa"
                filter
                @change="filtrar"
                showClear
                reset-filter-on-hide
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="name">Año</label>
            <Dropdown
                name="nombre"
                v-model="periodo.anio"
                :options="anios"
                placeholder="Seleccione un Año"
                filter
                :disabled="disabledAnios"
                @change="handlePeriodo"
                showClear
                reset-filter-on-hide
            />
          </div>
        </div>
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"/>
          </template>
        </Toolbar>
        <DataTable
            ref="dt"
            :value="registros"
            dataKey="id"
            :paginator="true"
            :rows="10"
            stripedRows
            :export-filename="filename"
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} empleados"
            responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Información</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"/>
                 <InputText v-model="filters['global'].value" placeholder="Search..."/>
              </span>
            </div>
          </template>
          <Column field="cc" header="Cedula" :sortable="true"/>
          <Column field="empleado" header="Empleado" :sortable="true"/>
          <Column field="salario" header="Salario" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.salario) }}
              </div>
            </template>
          </Column>
          <Column field="auxilio" header="Auxilio" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.auxilio) }}
              </div>
            </template>
          </Column>
          <Column field="desalarizado" header="Desalarizado" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.desalarizado) }}
              </div>
            </template>
          </Column>
          <Column field="salud" header="Salud" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.salud) }}
              </div>
            </template>
          </Column>
          <Column field="pensionEmpresa" header="Pension Empresa" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.pensionEmpresa) }}
              </div>
            </template>
          </Column>
          <Column field="descuentoTrabajador" header="Descuento Trabajador" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.descuentoTrabajador) }}
              </div>
            </template>
          </Column>
          <Column field="totalAportePension" header="Total Aporte Pension" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.totalAportePension) }}
              </div>
            </template>
          </Column>
          <Column field="arp" header="ARP" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.arp) }}
              </div>
            </template>
          </Column>
          <Column field="comfaboy" header="Comfaboy" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.comfaboy) }}
              </div>
            </template>
          </Column>
          <Column field="cesantias" header="Cesantias" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.cesantias) }}
              </div>
            </template>
          </Column>
          <Column field="interes" header="Interes" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.interes) }}
              </div>
            </template>
          </Column>
          <Column field="prima" header="Prima" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.prima) }}
              </div>
            </template>
          </Column>
          <Column field="vacaciones" header="Vacaciones" :sortable="true">
            <template #body="slotProps">
              <div>
                {{ formatCurrency(slotProps.data.vacaciones) }}
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'primevue/usetoast';
import apiService from '../service/api.service'

export default {
  data() {
    return {
      toast: useToast(),
      registros: [],
      empresas: [],
      empresa: {},
      periodos: [],
      anios: [],
      anio: {},
      periodo: {},
      filename: '',
      dt: [],
      mes: {},
      filters: {},
      submitted: false,
      edit: false,
      enablePeriodos: true,
      disabledAnios: true,
    };
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0
      }).format(value);
    },
    obtenerLista() {
      return apiService.getSalariosReporte(this.empresa, this.periodo).then(
          (response) => {
            this.registros = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.registros = [];
          }
      );
    },

    exportCSV() {
      if (this.$refs.dt && this.registros.length > 0) {
        this.$refs.dt.exportCSV();
      }
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    }, filtrar() {
      if (this.empresa !== null) {
        this.disabledAnios = false;
      } else {
        this.disabledAnios = true;
        this.registros = [];
      }

    }, handlePeriodo() {
      if (this.periodo.anio !== null) {
        this.enablePeriodos = false;
        this.obtenerLista();
        this.filename = this.empresa.nombre + '_' + this.periodo.anio
        this.filtrar();
        this.obtenerPeriodos()
      } else {
        this.enablePeriodos = true;
        this.obtenerLista();
      }
    },
    obtenerPeriodos() {
      return apiService.getPeriodosAnio(this.periodo.anio).then(
          (response) => {
            this.periodos = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerEmpresas() {
      return apiService.getEmpresas().then(
          (response) => {
            this.empresas = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerAnios() {
      return apiService.getPeriodosAnios().then(
          (response) => {
            this.anios = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
  },
  beforeMount() {
    this.initFilters();
  }
  ,
  mounted() {
    this.obtenerAnios();
    this.obtenerEmpresas();
  }
}
;
</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h1> Crear Registro Mensual </h1>
        <Toast/>
        <SalarioForm/>
      </div>
    </div>
  </div>
</template>

<script>


import {defineComponent} from "vue";
import SalarioForm from "@/components/SalarioForm.vue";

export default defineComponent({
  components: {SalarioForm}
})
</script>

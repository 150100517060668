<template>
  <div class="layout-footer">
    <span class="font-medium ml-2">Digital Codex S.A.S</span>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>

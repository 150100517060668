<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h1> Empleados </h1>
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
              <Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                      :disabled="!selectedEmpleados || !selectedEmpleados.length"/>
            </div>
          </template>
          <template v-slot:end>
            <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"/>
          </template>
        </Toolbar>
        <DataTable
            ref="dt"
            :value="empleados"
            v-model:selection="selectedEmpleados"
            dataKey="identificacion"
            :paginator="true"
            :rows="10"
            stripedRows
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} empleados"
            responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gestionar Empleados</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"/>
                 <InputText v-model="filters['global'].value" placeholder="Search..."/>
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="empresa.nombre" header="Empresa" :sortable="true"
                  headerStyle="width:30%; min-width:10rem;"/>
          <Column field="identificacion" header="Identificación" :sortable="true"
                  headerStyle="width:15%; min-width:10rem;"/>
          <Column field="primerNombre" header="Primer Nombre" :sortable="true"
                  headerStyle="width:15%; min-width:10rem;"/>
          <Column field="segundoNombre" header="Segundo Nombre" :sortable="true"
                  headerStyle="width:15%; min-width:10rem;"/>
          <Column field="primerApellido" header="Primer Apellido" :sortable="true"
                  headerStyle="width:15%; min-width:10rem;"/>
          <Column field="segundoApellido" header="Segundo Apellido" :sortable="true"
                  headerStyle="width:15%; min-width:10rem;"/>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                      @click="editEmpleado(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                      @click="confirmDeleteEmpleado(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="empleadosDialog" :style="{ width: '450px' }" header="Detalles de Empleado"
                :modal="true"
                class="p-fluid">
          <div class="field">
            <label for="name">Empresa</label>
            <Dropdown
                name="nombre"
                v-model="empleado.empresa"
                :options="empresas"
                optionLabel="nombre"
                placeholder="Seleccione una Empresa"
                filter
                showClear
            />
          </div>
          <div class="field">
            <label for="name">Tipo Identificación</label>
            <InputText id="name" v-model.trim="empleado.tipoIdentificacion" required="true" autofocus
                       :class="{ 'p-invalid': submitted && !empleado.tipoIdentificacion }"/>
            <small class="p-invalid" v-if="submitted && !empleado.tipoIdentificacion">El Tipo Identificacion es
              requerida</small>
          </div>
          <div class="field">
            <label for="name">Identificación</label>
            <InputText id="name" v-model.trim="empleado.identificacion" required="true" autofocus
                       :class="{ 'p-invalid': submitted && !empleado.identificacion }"/>
            <small class="p-invalid" v-if="submitted && !empleado.identificacion">La identificacion es requerida</small>
          </div>
          <div class="field">
            <label for="name">Primer Nombre</label>
            <InputText id="name" v-model.trim="empleado.primerNombre" required="true" autofocus
                       :class="{ 'p-invalid': submitted && !empleado.primerNombre }"/>
            <small class="p-invalid" v-if="submitted && !empleado.primerNombre">El nombre es requerido.</small>
          </div>
          <div class="field">
            <label for="name">Segundo Nombre</label>
            <InputText id="name" v-model.trim="empleado.segundoNombre" required="true" autofocus/>
          </div>
          <div class="field">
            <label for="name">Primer Apellido</label>
            <InputText id="name" v-model.trim="empleado.primerApellido" required="true" autofocus
                       :class="{ 'p-invalid': submitted && !empleado.primerApellido }"/>
            <small class="p-invalid" v-if="submitted && !empleado.primerApellido">El apellido es requerido.</small>
          </div>
          <div class="field">
            <label for="name">Segundo Apellido</label>
            <InputText id="name" v-model.trim="empleado.segundoApellido" required="true" autofocus/>
          </div>


          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button v-if="!edit" label="Confirmar" icon="pi pi-check" class="p-button-text" @click="saveEmpleado"/>
            <Button v-if="edit" label="Editar" icon="pi pi-check" class="p-button-text" @click="editarEmpleado"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteEmpleadoDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="empleado"
            >¿¿Estás seguro de que quieres eliminar al empleado con Identificación  <b>{{ empleado.identificacion }}</b
            >?</span
            >
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEmpleadoDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteEmpleado"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteEmpleadosDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="empleado">¿Estás seguro de que quieres eliminar los empleados seleccionados?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEmpleadosDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelectedEmpleados"/>
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'primevue/usetoast';
import apiService from '../service/api.service'

export default {
  data() {
    return {
      toast: useToast(),
      empleados: [],
      empresas: [],
      empleadosDialog: false,
      deleteEmpleadoDialog: false,
      deleteEmpleadosDialog: false,
      empleado: {},
      selectedEmpleados: [],
      dt: [],
      filters: {},
      submitted: false,
      edit: false,
    };
  },
  methods: {
    openNew() {
      this.empleado = {};
      this.submitted = false;
      this.empleadosDialog = true;
      this.edit = false;
    },
    hideDialog() {
      this.empleadosDialog = false;
      this.submitted = false;
      this.edit = false;
    },
    saveEmpleado() {
      this.submitted = true;
      this.crearEmpleado();
    },
    editarEmpleado() {
      this.updateEmpleado();
      this.edit = false;
    },
    editEmpleado(editCliente) {
      this.empleado = {...editCliente};
      this.edit = true;
      this.empleadosDialog = true;
    },
    confirmDeleteEmpleado(editCliente) {
      this.empleado = editCliente;
      this.deleteEmpleadoDialog = true;
    },
    deleteEmpleado() {
      this.borrarCliente()
    },
    crearEmpleado() {
      return apiService.newEmpleado(this.empleado).then(
          () => {
            this.empleados.push(this.empleado);
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Empleado Creado', life: 3000});
            this.empleadosDialog = false;
            this.empleado = {};
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    updateEmpleado() {
      return apiService.actualizarEmpleado(this.empleado).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Empleado Actualizado', life: 3000});
            this.empleadosDialog = false;
            this.empleado = {};
            this.obtenerEmpleados();
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    borrarCliente() {
      return apiService.borrarEmpleado(this.empleado).then(
          (response) => {
            this.empleados = this.empleados.filter((val) => val.identificacion !== this.empleado.identificacion);
            this.deleteempleadosDialog = false;
            this.empleado = {};
            this.toast.add({severity: 'success', summary: 'Successful', detail: response.data, life: 3000});
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    borrarListaCliente() {
      return apiService.borrarListaEmpleados(this.selectedEmpleados).then(
          (response) => {
            this.empleados = this.empleados.filter((val) => !this.selectedEmpleados.includes(val));
            this.deleteEmpleadosDialog = false;
            this.selectedEmpleados = null;
            this.toast.add({severity: 'success', summary: 'Successful', detail: response.data, life: 3000});
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerEmpleados() {
      return apiService.getEmpleados().then(
          (response) => {
            this.empleados = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerEmpresas() {
      return apiService.getEmpresas().then(
          (response) => {
            this.empresas = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    exportCSV() {
      if (this.$refs.dt) {
        this.$refs.dt.exportCSV();
      }
    },
    confirmDeleteSelected() {
      this.deleteEmpleadosDialog = true;
    },
    deleteSelectedEmpleados() {
      this.borrarListaCliente()
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    }
  }
  ,
  beforeMount() {
    this.initFilters();
  }
  ,
  mounted() {
    this.obtenerEmpleados();
    this.obtenerEmpresas();
  }
}
;
</script>

<script setup>
import {ref} from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
  {
    label: 'Home',
    items: [{label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'}]
  },{
    label: 'Empresas',
    items: [
      {label: 'Gestionar', icon: 'pi pi-fw pi-box', to: '/empresas'},
      {label: 'Empleados', icon: 'pi pi-fw pi-id-card', to: '/empleados'}
    ]
  },{
    label: 'Base Salarial',
    items: [
      {label: 'Datos Básicos', icon: 'pi pi-fw pi-box', to: '/datos-base'},
      {label: 'Listar', icon: 'pi pi-fw pi-plus', to: '/listar-base'},
    ]
  },
  {
    label: 'Salarios',
    items: [
      {label: 'Crear', icon: 'pi pi-fw pi-plus', to: '/salario'},
      {label: 'Listar', icon: 'pi pi-fw pi-list', to: '/listar-salarios'},
      {label: 'Cargar Archivo', icon: 'pi pi-fw pi-upload', to: '/upload'}
    ]
  },{
    label: 'Reportes',
    items: [
      {label: 'Generar', icon: 'pi pi-fw pi-book', to: '/reporte'},
    ]
  }
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>

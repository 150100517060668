<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div>
          <h1>Lista Bases Salariales</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <DataTable :value="registros"
                   ref="dt"
                   dataKey="id"
                   :paginator="true"
                   :rows="10"
                   stripedRows
                   scrollable
                   :filters="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5, 10, 25]"
                   currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} empleados"
                   responsiveLayout="scroll">
          <Column field="codigo" header="Nombre" style="min-width: 200px" frozen class="font-bold"></Column>
          <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header">
            <template #body="{ data, field}">
              <div v-if="col.tipo === 1">
                {{ formatCurrency(data[field]) }}
              </div>
              <div v-else-if="col.tipo === 2" style="text-align: center">
                {{ data[field] }}%
              </div>
            </template>
          </Column>
          <Column :exportable="true" style="min-width:8rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                      @click="edit(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="baseDialog" :style="{ width: '1000px' }" header="Detalle Base Salarial" :modal="true"
          class="p-fluid">
    <DatosSalarioForm :selected="registro" :editar="true" @cerrar-modal="cerrarModal"></DatosSalarioForm>
  </Dialog>
</template>

<script>
import apiService from "@/service/api.service";
import {FilterMatchMode} from "primevue/api";
import DatosSalarioForm from "@/components/BaseForm.vue";

export default {
  name: "ListarEstudios",
  components: {DatosSalarioForm},
  data() {
    return {
      baseDialog: false,
      registros: [],
      registro: {},
      expandedRows: [],
      filters: {},
      dt: [],
      columns: [
        {field: 'salarioBase', header: 'Salario', tipo: 1},
        {field: 'auxilioTransporte', header: 'Auxilio', tipo: 1},
        {field: 'salud', header: 'Salud', tipo: 2},
        {field: 'aporteEmpresa', header: 'Aporte Empresa', tipo: 2},
        {field: 'fondoSolidaridad', header: 'Fondo Sol.', tipo: 2},
        {field: 'descuentoTrabajador', header: 'Desc. Empleado', tipo: 2},
        {field: 'retencion', header: 'Retención', tipo: 2},
        {field: 'arp', header: 'ARL', tipo: 2},
        {field: 'comfaboy', header: 'Comfaboy', tipo: 2},
        {field: 'cesantias', header: 'Cesantías', tipo: 2},
        {field: 'interes', header: 'Interés', tipo: 2},
        {field: 'prima', header: 'Prima', tipo: 2},
        {field: 'vacaciones', header: 'Vacaciones', tipo: 2}
      ],
    }
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0
      }).format(value);
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    },

    edit(editCliente) {
      this.baseDialog = true;
      this.registro = editCliente;
    },
    cerrarModal() {
      this.obtenerLista();
      this.baseDialog = false;
    },
    obtenerLista() {
      return apiService.getBase().then(
          (response) => {
            this.registros = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.registros = [];
          }
      );
    },
  },
  beforeMount() {
    this.initFilters();
  },
  mounted() {
    this.obtenerLista();
  }

}

</script>




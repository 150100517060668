<script>
import Login from "@/views/pages/auth/Login.vue";
import store from "@/store";
import AppLayout from "@/components/layout/AppLayout.vue";

export default {
  components: {AppLayout, Login},
  computed: {
    loggedIn() {
      return store.getters.IsLoggedIn;
    },
  }
};
</script>

<template>
  <!--  <router-view/>-->
  <Login v-if="!loggedIn"></Login>
  <div v-else>
    <AppLayout></AppLayout>
  </div>
</template>

import {createRouter, createWebHashHistory} from 'vue-router';
import store from '@/store';
import Dashboard from "@/views/Dashboard.vue";
import Empresas from "@/views/Empresas.vue";
import Empleados from "@/views/Empleados.vue";
import CargaArchivo from "@/views/CargaArchivo.vue";
import Reporte from "@/views/Reporte.vue";
import DatosBase from "@/views/DatosBase.vue";
import ListarSalarios from "@/views/ListarSalarios.vue";
import SalarioPeriodo from "@/views/SalarioPeriodo.vue";
import ListarBases from "@/views/ListarBases.vue";
import Login from "@/views/pages/auth/Login.vue";

const routes = [
    {path: '/', name: 'dashboard', component: Dashboard, meta: {requiresAuth: true}},
    {path: '/empresas', name: 'empresas', component: Empresas, meta: {requiresAuth: true}},
    {path: '/empleados', name: 'empleados', component: Empleados, meta: {requiresAuth: true}},
    {path: '/upload', name: 'upload', component: CargaArchivo, meta: {requiresAuth: true}},
    {path: '/reporte', name: 'reporte', component: Reporte, meta: {requiresAuth: true}},
    {path: '/datos-base', name: 'datos-base', component: DatosBase, meta: {requiresAuth: true}},
    {path: '/listar-salarios', name: 'listar-salarios', component: ListarSalarios, meta: {requiresAuth: true}},
    {path: '/salario', name: 'salario', component: SalarioPeriodo, meta: {requiresAuth: true}},
    {path: '/listar-base', name: 'lista-base', component: ListarBases, meta: {requiresAuth: true}},
    {path: '/auth/login', name: 'login', component: Login}
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        store.dispatch('validateToken')
        if (store.getters.IsLoggedIn) {
            next();
        } else {
            next({name: 'login'});
        }
    } else {
        next();
    }
});

export default router;

<!--<template>-->

<!--</template>-->

<!--<script>-->
<!--import apiService from "@/service/api.service";-->
<!--import {useToast} from "primevue/usetoast";-->

<!--export default {-->
<!--  name: "CargaEstudio",-->
<!--  data() {-->
<!--    return {-->
<!--      deshabilitarCarga: false,-->
<!--      toast: useToast(),-->
<!--      file: [],-->
<!--      totalSize: 0,-->
<!--      totalSizePercent: 0,-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    onUpload(event) {-->
<!--      apiService.uploadFile(event.files[0], '900933459', 2024).then(-->
<!--          (response) => {-->
<!--            this.toast.add({severity: 'success', summary: 'Successful', detail: response.data, life: 3000});-->
<!--            // this.$router.push('/listar-salarios')-->
<!--          },-->
<!--          (error) => {-->
<!--            this.message =-->
<!--                (error.response &&-->
<!--                    error.response.data &&-->
<!--                    error.response.data.message) ||-->
<!--                error.message ||-->
<!--                error.toString();-->
<!--            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data});-->
<!--          }-->
<!--      );-->
<!--    }-->
<!--  }-->


<!--}-->
<!--</script>-->
<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div>
          <h1> Carga Archivo Nóminas</h1>
          <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-6">
              <label for="name">Empresa</label>
              <Dropdown
                  name="nombre"
                  v-model="empresa"
                  :options="empresas"
                  optionLabel="nombre"
                  placeholder="Seleccione una Empresa"
                  filter
                  @change="enableLoad"
                  showClear
                  reset-filter-on-hide
              />
            </div>
            <div class="field col-12 md:col-6">
              <label for="name">Año</label>
              <Dropdown
                  name="nombre"
                  v-model="anio"
                  :options="anios"
                  placeholder="Seleccione un Año"
                  filter
                  @change="enableLoad"
                  showClear
                  reset-filter-on-hide
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <Toast/>
    <FileUpload name="demo[]" :multiple="true" :maxFileSize="1000000" @select="onSelectedFiles">
      <template #header="{ chooseCallback, clearCallback, files }">
        <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
          <div class="flex gap-2">
            <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined :disabled="disabledLoad"></Button>
            <Button @click="uploadEvent(files)" icon="pi pi-cloud-upload" rounded outlined severity="success"
                    :disabled="!files || files.length === 0"></Button>
            <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger"
                    :disabled="!files || files.length === 0"></Button>
          </div>
          <ProgressBar :value="totalSizePercent" :showValue="false"
                       :class="['md:w-20rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 100 }]"
          ><span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span></ProgressBar
          >
        </div>
      </template>
      <template #content="{ files, removeUploadedFileCallback, removeFileCallback }">
        <div v-if="completed === 0">
          <h5>Pending</h5>
          <div class="p-0 sm:p-5 gap-5">
            <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                 class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
              <div>
                <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50"/>
              </div>
              <span class="font-semibold">{{ file.name }}</span>
              <div>{{ formatSize(file.size) }}</div>
              <Badge value="Pending" severity="warning"/>
              <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined
                      rounded severity="danger"/>
            </div>
          </div>
        </div>

        <div v-if="completed === 1">
          <h5>Completed</h5>
          <div class="flex-wrap p-0 sm:p-5 gap-5">
            <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                 class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
              <div>
                <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50"/>
              </div>
              <span class="font-semibold">{{ file.name }}</span>
              <div>{{ formatSize(file.size) }}</div>
              <Badge value="Completed" class="mt-3" severity="success"/>
              <Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded severity="danger"/>
            </div>
          </div>
        </div>
        <div v-if="completed === 2">
          <h5>Error</h5>
          <div class="flex-wrap p-0 sm:p-5 gap-5">
            <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                 class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
              <div>
                <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50"/>
              </div>
              <span class="font-semibold">{{ file.name }}</span>
              <div>{{ formatSize(file.size) }}</div>
              <Badge value="Error" class="mt-3" severity="danger"/>

              <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined
                      rounded severity="danger"/>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div class="flex align-items-center justify-content-center flex-column">
          <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"/>
          <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

<script>
import apiService from "@/service/api.service";
import {useToast} from "primevue/usetoast";

export default {
  data() {
    return {
      anios: [],
      anio: 0,
      empresas: [],
      empresa: {},
      toast: useToast(),
      files: [],
      totalSize: 0,
      totalSizePercent: 0,
      completed: 0,
      disabledLoad: true,
    };
  },
  methods: {
    onRemoveTemplatingFile(file, removeFileCallback, index) {
      removeFileCallback(index);
      this.totalSize -= parseInt(this.formatSize(file.size));
      this.totalSizePercent = this.totalSize / 10;
      this.completed = 0;
    },
    enableLoad() {
      if (this.anio > 0 && this.empresa?.nit) {
        this.disabledLoad = false;
      }
    },
    onSelectedFiles(event) {
      this.files = event.files;
      this.files.forEach((file) => {
        this.totalSize += parseInt(this.formatSize(file.size));
      });
    },
    onUpload(files) {
      apiService.uploadFile(files[0], this.empresa.nit, this.anio).then(
          (response) => {
            this.completed = 1;
            this.toast.add({severity: 'success', summary: 'Successful', detail: response.data, life: 3000});
            this.$router.push('/listar-salarios')
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.completed = 2;
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data});
          }
      );
    },
    uploadEvent(event) {
      this.totalSizePercent = this.totalSize / 10;
      this.onUpload(event)
    },
    formatSize(bytes) {
      const k = 1024;
      const dm = 3;
      const sizes = this.$primevue.config.locale.fileSizeTypes;
      if (bytes === 0) {
        return `0 ${sizes[0]}`;
      }
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
      return `${formattedSize} ${sizes[i]}`;
    },
    obtenerAnios() {
      return apiService.getPeriodosAnios().then(
          (response) => {
            this.anios = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerEmpresas() {
      return apiService.getEmpresas().then(
          (response) => {
            this.empresas = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
  },
  mounted() {
    this.obtenerEmpresas();
    this.obtenerAnios();
  }
};
</script>


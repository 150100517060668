import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import AuthService from '../service/auth.service';

const store = createStore({
    plugins: [createPersistedState()],
    state() {
        return {
            isLoggedIn: false,
            currentUser: null,
            token: null,
            firebaseId: null,
            toggle: false,
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.isLoggedIn = true;
            state.currentUser = user;
        },
        loginFailure(state) {
            state.isLoggedIn = false;
            state.currentUser = null;
        },
        setToken(state, token) {
            state.token = token;
        },
        setFirebaseId(state, firebaseId) {
            state.firebaseId = firebaseId;
        },

    },
    actions: {
        async login({commit}, {user, password}) {
            try {
                const success = await AuthService.login(user, password);
                const idToken = await success.user.getIdToken();
                commit('setFirebaseId', success.user.uid);
                commit('setToken', idToken);
                commit('loginSuccess', success.user);
                return Promise.resolve(success);
            } catch (error) {
                commit('loginFailure');
                return Promise.reject(error);
            }
        },
        async validateToken({ commit }) {
            try {
                await AuthService.initializeAuthStateListener();
            } catch (error) {
                commit('loginFailure');
            }
        },

        async loginGoogle({commit}) {
            try {
                const success = await AuthService.loginGoogle();
                const idToken = await success.user.getIdToken();
                commit('setFirebaseId', success.user.uid);
                commit('setToken', idToken);
                commit('loginSuccess', success.user);
                return Promise.resolve(success);
            } catch (error) {
                commit('loginFailure');
                return Promise.reject(error);
            }
        },
        async logout({commit}) {
            try {
                const success = await AuthService.logout();
                commit('setFirebaseId', null);
                commit('setToken', null);
                commit('loginFailure');
                return Promise.resolve(success);
            } catch (error) {
                commit('loginFailure');
                return Promise.reject(error);
            }
        },
    },
    getters:{
        getToken(state){
            return state.token;
        },
        getFirebaseId(state){
            return state.firebaseId;
        },
        IsLoggedIn(state){
            return state.isLoggedIn;
        },
    }
});

export default store;

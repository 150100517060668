import axios from 'axios';
import store from '@/store/index';

const instance = axios.create();

instance.interceptors.request.use((config) => {
    config.headers['x-auth'] = store.state.firebaseId
    config.headers['Authorization'] = store.state.token
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default instance;

<script>
import AppTopbar from './AppTopbar.vue';
import AppFooter from './AppFooter.vue';
import AppSidebar from './AppSidebar.vue';
import {useLayout} from '@/components/composables/layout';
import {watch} from "vue";

export default {
  components: {
    AppTopbar,
    AppFooter,
    AppSidebar,
  },
  data() {
    return {
      outsideClickListener: null,
      onMenuToggle:null
    };
  },
  computed: {
    containerClass() {
      const {layoutConfig, layoutState} = useLayout();
      return {
        'layout-theme-light': layoutConfig.darkTheme.value === 'light',
        'layout-theme-dark': layoutConfig.darkTheme.value === 'dark',
        'layout-overlay': layoutConfig.menuMode.value === 'overlay',
        'layout-slim': layoutConfig.menuMode.value === 'slim',
        'layout-static': layoutConfig.menuMode.value === 'static',
        'layout-reveal': layoutConfig.menuMode.value === 'reveal',
        'layout-static-inactive':
            layoutState.staticMenuDesktopInactive.value &&
            layoutConfig.menuMode.value === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive.value,
        'layout-mobile-active': layoutState.staticMenuMobileActive.value,
        'p-ripple-disabled': layoutConfig.ripple.value === false,
      };
    },

  },

  methods: {
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (this.isOutsideClicked(event)) {
            this.onMenuToggle();
            // this.layoutState.staticMenuMobileActive = false;
            // this.layoutState.menuHoverActive = false;
          }
        };
        document.addEventListener('click', this.outsideClickListener);
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.removeEventListener('click', this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },
    isOutsideClicked(event) {
      const sidebarEl = document.querySelector('.layout-sidebar');
      const topbarEl = document.querySelector('.layout-menu-button');

      return !(
          sidebarEl.isSameNode(event.target) ||
          sidebarEl.contains(event.target) ||
          topbarEl.isSameNode(event.target) ||
          topbarEl.contains(event.target)
      );
    },
  },
  created() {
    const {isSidebarActive} = useLayout();
    this.onMenuToggle =  useLayout().onMenuToggle;
    watch(isSidebarActive, (newVal) => {
      if (newVal) {
        this.bindOutsideClickListener();
      } else {
        this.unbindOutsideClickListener();
      }
    });
  },
};
</script>
<template>
  <div class="layout-wrapper" :class="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view></router-view>
      </div>
      <app-footer></app-footer>
    </div>
    <div class="layout-mask"></div>
  </div>
  <Toast/>
</template>


import firebase from "firebase/compat/app";
import 'firebase/compat/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAnCKT4AksCMOTiWV2cxpjn_dFbffT8CPc",
    authDomain: "pruebafirebase-c6427.firebaseapp.com",
    projectId: "pruebafirebase-c6427",
    storageBucket: "pruebafirebase-c6427.appspot.com",
    messagingSenderId: "1085733604947",
    appId: "1:1085733604947:web:a73480973f36077afd00a6",
    measurementId: "G-KBE1ZTD072"
};
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth()

export {
    auth
}

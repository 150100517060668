<template>
  <div class="col-12">
    <div class="p-fluid formgrid grid">
      <div class="col-12 md:col-12">
        <Fieldset legend="Datos Empleado">
          <div class="field grid col-12 md:col-12">
            <div class="field col-12 md:col-6">
              <label for="name">Empresa</label>
              <Dropdown
                  name="nombre"
                  v-model="empresa"
                  :options="empresas"
                  optionLabel="nombre"
                  placeholder="Seleccione una Empresa"
                  filter
                  @change="handleSelect"
                  showClear
                  reset-filter-on-hide
              />
            </div>
            <div class="field col-12 md:col-6">
              <label for="municipio">Empleado</label>
              <Dropdown
                  name="nombre"
                  v-model="salario.empleado"
                  :options="empleados"
                  optionLabel="identificacion"
                  :disabled="enableEmpleados"
                  placeholder="Seleccione un Empleado"
                  showClear
                  filter
                  reset-filter-on-hide
              />
            </div>
          </div>

        </Fieldset>
      </div>
      <div class="col-12 md:col-12">
        <Fieldset legend="Periodo">
          <div class="field grid col-12 md:col-12">
            <div class="field col-12 md:col-6">
              <label for="name">Año</label>
              <Dropdown
                  name="nombre"
                  v-model="anio"
                  :options="anios"
                  placeholder="Seleccione un Año"
                  filter
                  @change="handlePeriodo"
                  showClear
                  reset-filter-on-hide
              />
            </div>
            <div class="field col-12 md:col-6">
              <label for="municipio">Mes</label>
              <Dropdown
                  name="nombre"
                  v-model="salario.periodo"
                  :options="periodos"
                  optionLabel="nombreMes"
                  :disabled="enablePeriodos"
                  placeholder="Seleccione un Mes"
                  showClear
                  filter
                  reset-filter-on-hide
              />
            </div>
          </div>

        </Fieldset>
      </div>
      <div class="col-12 md:col-12">
        <Fieldset legend="Datos Salario">
          <div class="field grid col-12 md:col-12">
            <div class="field col-12 md:col-6">
              <label for="name">Dias Trabajados</label>
              <InputNumber id="x" v-model="salario.diasTrabajados"  :step=1
                           :min=1
                           :max=30
                           showButtons/>
            </div>

            <div class="field col-12 md:col-6">
              <label for="name">Desalarizado</label>
              <InputNumber id="x" v-model="salario.desalarizado" prefix="$"
                           showButtons/>
            </div>
          </div>

        </Fieldset>
      </div>
      <div class="col-12 md:col-12">
        <Fieldset legend="Datos Salario">
          <div class="field grid col-12 md:col-12">
            <div class="field col-12 md:col-6">
              <label for="name">Salario</label>
              <Dropdown
                  name="nombre"
                  v-model="salario.salario"
                  :options="salarios"
                  optionLabel="codigo"
                  placeholder="Seleccione un Salario"
                  filter
                  showClear
                  reset-filter-on-hide
              />
            </div>
          </div>
        </Fieldset>
      </div>
      <div class="field py-5 col-12 md:col-4 md:col-offset-4">
        <Button v-if="!editar" label="Guardar" icon="pi pi-save" iconPos="right" @click="crearSalario"/>
        <Button v-if="editar" label="Editar" icon="pi pi-save" iconPos="right" @click="editarEstudio"/>
      </div>
    </div>
  </div>
</template>

<script>
import {useToast} from "primevue/usetoast";
import apiService from "@/service/api.service";

export default {
  name: "SalarioForm",
  props: [
    'selected',
    'editar'
  ],
  data() {
    return {
      toast: useToast(),
      empresas:[],
      empresa:{},
      empleado : {},
      empleados:[],
      periodos:[],
      salarios:[],
      anios:[],
      anio:{},
      periodo:{},
      salario:{diasTrabajados:1,desalarizado:0,},
      enableEmpleados: true,
      enablePeriodos: true,
    }
  },
  methods: {
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0});
    },
    crearSalario() {
      return apiService.newSalarioPeriodo(this.salario).then(
          () => {
            this.toast.add({
              severity: 'success',
              summary: 'Successful',
              detail: 'Salario Creado Correctamente',
              life: 3000
            });
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 3000});
          }
      );
    },
    handleSelect() {
      if (this.empresa !== null) {
        this.enableEmpleados = false;
      } else {
        this.enableEmpleados = true;
        this.empleado.empresa = {};
      }
      this.obtenerEmpleados()
    },
    handlePeriodo() {
      if (this.anio !== null) {
        this.enablePeriodos = false;
      } else {
        this.enablePeriodos = true;
      }
      this.obtenerPeriodos()
    },
    obtenerEmpresas() {
      return apiService.getEmpresas().then(
          (response) => {
            this.empresas = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerEmpleados() {
      return apiService.getEmpleadosEmpresa(this.empresa).then(
          (response) => {
            this.empleados = response.data;
            console.log(response.data)
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerPeriodos() {
      return apiService.getPeriodosAnio(this.anio).then(
          (response) => {
            this.periodos = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerAnios() {
      return apiService.getPeriodosAnios().then(
          (response) => {
            this.anios = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    editarEstudio() {
      return apiService.editarSalario(this.salario).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Cliente Created', life: 3000});
            this.$emit('cerrar-modal');
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerSalarios() {
      return apiService.getBase().then(
          (response) => {
            this.salarios = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
  },
  mounted() {
    this.obtenerEmpresas();
    this.obtenerAnios();
    this.obtenerSalarios();
    if (this.editar) {
      this.salario = this.selected;
      this.empresa = this.selected.empleado.empresa
      this.obtenerEmpleados();
      this.anio = this.selected.periodo.anio
      this.obtenerPeriodos();
      this.enablePeriodos=false;
      this.salario.periodo = this.selected.periodo

    }
  }
}
</script>



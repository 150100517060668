<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h1> Empresas </h1>
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
              <Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                      :disabled="!selectedEmpresas || !selectedEmpresas.length"/>
            </div>
          </template>
          <template v-slot:end>
            <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"/>
          </template>
        </Toolbar>
        <DataTable
            ref="dt"
            :value="empresas"
            v-model:selection="selectedEmpresas"
            dataKey="identificacion"
            :paginator="true"
            :rows="10"
            stripedRows
            :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} empresas"
            responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gestionar Empresas</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"/>
                 <InputText v-model="filters['global'].value" placeholder="Search..."/>
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="nit" header="Nit" :sortable="true"
                  headerStyle="width:20%; min-width:10rem;"/>
          <Column field="nombre" header="Nombre" :sortable="true"
                  headerStyle="width:30%; min-width:10rem;"/>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                      @click="editEmpresa(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                      @click="confirmDeleteEmpresa(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
        <Dialog v-model:visible="empresasDialog" :style="{ width: '450px' }" header="Detalles de Empresa"
                :modal="true"
                class="p-fluid">
          <div class="field">
            <label for="name">Nit</label>
            <InputText id="name" v-model.trim="empresa.nit" required="true" autofocus
                       :class="{ 'p-invalid': submitted && !empresa.nit }"/>
            <small class="p-invalid" v-if="submitted && !empresa.nit">El nit es requerido.</small>
          </div>
          <div class="field">
            <label for="name">Nombre</label>
            <InputText id="name" v-model.trim="empresa.nombre" required="true" autofocus/>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Confirmar" icon="pi pi-check" class="p-button-text" @click="saveEmpresa"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteEmpresaDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="empresa"
            >¿¿Estás seguro de que quieres eliminar al empresa con Identificación  <b>{{ empresa.nit }}</b
            >?</span
            >
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEmpresaDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteEmpresa"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteEmpresasDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span v-if="empresa">¿Estás seguro de que quieres eliminar los empresas seleccionados?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEmpresasDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelectedEmpresas"/>
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'primevue/usetoast';
import apiService from '../service/api.service'

export default {
  data() {
    return {
      toast: useToast(),
      empresas: [],
      empresasDialog: false,
      deleteEmpresaDialog: false,
      deleteEmpresasDialog: false,
      empresa: {},
      selectedEmpresas: [],
      dt: [],
      filters: {},
      submitted: false,
      edit: false,
    };
  },
  methods: {
    openNew() {
      this.empresa = {};
      this.submitted = false;
      this.empresasDialog = true;
    },
    hideDialog() {
      this.empresasDialog = false;
      this.submitted = false;
      this.edit = false;
    },
    saveEmpresa() {
      this.submitted = true;
      if (!this.edit) {
        this.crearEmpresa();
      } else {
        this.updateEmpresa();
        this.edit = false;
      }
    },
    editEmpresa(editCliente) {
      this.empresa = {...editCliente};
      this.edit = true;
      this.empresasDialog = true;
    },
    confirmDeleteEmpresa(editCliente) {
      this.empresa = editCliente;
      this.deleteEmpresaDialog = true;
    },
    deleteEmpresa() {
      this.borrarCliente()
    },
    crearEmpresa() {
      return apiService.newEmpresa(this.empresa).then(
          () => {
            this.empresas.push(this.empresa);
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Empresa Creado', life: 3000});
            this.empresasDialog = false;
            this.empresa = {};
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    updateEmpresa() {
      return apiService.actualizarEmpresa(this.empresa).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Empresa Actualizado', life: 3000});
            this.empresasDialog = false;
            this.empresa = {};
            this.obtenerEmpresas();
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    borrarCliente() {
      return apiService.borrarEmpresa(this.empresa).then(
          (response) => {
            this.empresas = this.empresas.filter((val) => val.nit !== this.empresa.nit);
            this.deleteEmpresaDialog = false;
            this.empresa = {};
            this.toast.add({severity: 'success', summary: 'Successful', detail: response.data, life: 3000});
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    borrarListaCliente() {
      return apiService.borrarListaEmpresas(this.selectedEmpresas).then(
          (response) => {
            this.empresas = this.empresas.filter((val) => !this.selectedEmpresas.includes(val));
            this.deleteEmpresasDialog = false;
            this.selectedEmpresas = null;
            this.toast.add({severity: 'success', summary: 'Successful', detail: response.data, life: 3000});
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    obtenerEmpresas() {
      return apiService.getEmpresas().then(
          (response) => {
            this.empresas = response.data;
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    exportCSV() {
      if (this.$refs.dt) {
        this.$refs.dt.exportCSV();
      } else {
        console.error('No se encontró la referencia al DataTable');
      }

    },
    confirmDeleteSelected() {
      this.deleteEmpresasDialog = true;
    },
    deleteSelectedEmpresas() {
      this.borrarListaCliente()
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    }
  }
  ,
  beforeMount() {
    this.initFilters();
  }
  ,
  mounted() {
    this.obtenerEmpresas();
  }
}
;
</script>

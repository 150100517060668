<template>
  <div class="col-12">
    <div class="p-fluid formgrid grid">
      <div class="col-12 md:col-12">
        <Fieldset legend="Datos Salariales">
          <div class="field grid col-12 md:col-12">
            <div class="field col-12 md:col-6">
              <label for="departamento">Nombre</label>
              <InputText id="codigo" v-model="datos.codigo" />
            </div>
          </div>
          <div class="field grid col-12 md:col-12">
            <div class="field col-12 md:col-6">
              <label for="departamento">Salario Base</label>
              <InputNumber id="salarioBase" v-model="datos.salarioBase" @input="calcularValores"
                           @change="calcularValores"
                           @blur="calcularValores" prefix="$" :step=10000 showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="municipio">Auxilio Transporte</label>
              <InputNumber id="auxilioTranporte" v-model="datos.auxilioTransporte" prefix="$" :step=10000 showButtons/>
            </div>
          </div>

        </Fieldset>
      </div>
      <div class="col-12 md:col-12">
        <Fieldset legend="Salud">
          <div class="field grid col-12 md:col-12">
            <div class="field col-12 md:col-6">
              <label for="departamento">Salud (%)</label>
              <InputNumber id="salud" v-model="datos.salud" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" inputId="stacked-buttons"
                           :step=0.1
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="valorSalud" v-model="datosValores.salud" disabled/>
            </div>
          </div>
        </Fieldset>
      </div>
      <div class="col-12 md:col-12">
        <Fieldset legend="Pension">
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Aporte Empresa (%)</label>
              <InputNumber id="x" v-model="datos.aporteEmpresa" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.aporteEmpresa" disabled/>
            </div>
          </div>
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Descuento Trabajador (%)</label>
              <InputNumber id="x" v-model="datos.descuentoTrabajador" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.descuentoTrabajador" disabled/>
            </div>
          </div>
        </Fieldset>
      </div>
      <div class="col-12 md:col-12">
        <Fieldset legend="Aportes Parafiscales">
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Cesantias(%)</label>
              <InputNumber id="x" v-model="datos.cesantias" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :minFractionDigits=4
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.cesantias" disabled/>
            </div>
          </div>
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Interes (%)</label>
              <InputNumber id="x" v-model="datos.interes" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.interes" disabled/>
            </div>
          </div>
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Prima (%)</label>
              <InputNumber id="x" v-model="datos.prima" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :max=100
                           :minFractionDigits=4
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.prima" disabled/>
            </div>
          </div>
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Vacaciones (%)</label>
              <InputNumber id="x" v-model="datos.vacaciones" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :minFractionDigits=4
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.vacaciones" disabled/>
            </div>
          </div>
        </Fieldset>
      </div>
      <div class="col-12 md:col-12">
        <Fieldset legend="Otros">
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Fondo Solidaridad(%)</label>
              <InputNumber id="x" v-model="datos.fondoSolidaridad" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.fondoSolidaridad" disabled/>
            </div>
          </div>
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Retención (%)</label>
              <InputNumber id="x" v-model="datos.retencion" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.retencion" disabled/>
            </div>
          </div>
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">ARP (%)</label>
              <InputNumber id="x" v-model="datos.arp" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.arp" disabled/>
            </div>
          </div>
          <div class="field grid col-12 md:col-16">
            <div class="field col-12 md:col-6">
              <label for="departamento">Comfaboy (%)</label>
              <InputNumber id="x" v-model="datos.comfaboy" @input="calcularValores" @change="calcularValores"
                           @blur="calcularValores" :step=0.1
                           :max=100
                           showButtons/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="departamento">Valor ($)</label>
              <InputText id="x" v-model="datosValores.comfaboy" disabled/>
            </div>
          </div>
        </Fieldset>
      </div>
      <div class="field py-5 col-12 md:col-4 md:col-offset-4">
        <Button v-if="!editar" label="Guardar" icon="pi pi-save" iconPos="right" @click="crearSalario"/>
        <Button v-if="editar" label="Editar" icon="pi pi-save" iconPos="right" @click="editarEstudio"/>
      </div>
    </div>
  </div>
</template>

<script>
import {useToast} from "primevue/usetoast";
import apiService from "@/service/api.service";

export default {
  name: "DatosSalarioForm",
  props: [
    'selected',
    'editar'
  ],
  data() {
    return {
      toast: useToast(),
      estudio: {},
      datos: {
        salarioBase: 1160000,
        auxilioTransporte: 140600,
        salud: 4,
        aporteEmpresa: 12,
        descuentoTrabajador: 4,
        cesantias: 8.3333,
        interes: 12,
        prima: 8.3333,
        vacaciones: 4.1666,
        fondoSolidaridad: 0,
        retencion: 0,
        arp: 6.96,
        comfaboy: 4
      },
      datosValores: {salud: 0},
      submitted: false,
      clientes: [],
      cliente: {},
      alternativas: [{}],
      alternativaActiva: 0,
      departamentos: [],
      municipios: [],
      enableMunicipios: true,
      departamento: {}
    }
  },
  methods: {
    obtenerValor(valor, tipo, redondeo) {
      switch (tipo) {
        case 1:
          return Math.ceil((valor / 100 * this.datos.salarioBase) / Math.pow(10, redondeo)) * Math.pow(10, redondeo);
        case 2:
          return Math.ceil((valor / 100 * (this.datos.salarioBase + this.datos.auxilioTransporte)) / Math.pow(10, redondeo)) * Math.pow(10, redondeo);
        default:
          break;
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0
      }).format(value);
    },
    editarEstudio() {
      return apiService.editarBase(this.datos).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Base Editada', life: 3000});
            this.$emit('cerrar-modal');
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    calcularValores() {
      this.datosValores.salud = this.formatCurrency(this.obtenerValor(this.datos.salud, 1, 2));
      this.datosValores.aporteEmpresa = this.formatCurrency(this.obtenerValor(this.datos.aporteEmpresa, 1, 2));
      this.datosValores.descuentoTrabajador = this.formatCurrency(this.obtenerValor(this.datos.descuentoTrabajador, 1, 2));
      this.datosValores.cesantias = this.formatCurrency(this.obtenerValor(this.datos.cesantias, 2, 1));
      this.datosValores.interes = this.formatCurrency(Math.round(this.datos.interes / 100 * this.obtenerValor(this.datos.cesantias, 2, 1)));
      this.datosValores.prima = this.formatCurrency(this.obtenerValor(this.datos.prima, 2, 1));
      this.datosValores.vacaciones = this.formatCurrency(Math.floor((this.datos.vacaciones / 100 * this.datos.salarioBase) / 10) * 10);
      this.datosValores.fondoSolidaridad = this.formatCurrency(this.obtenerValor(this.datos.fondoSolidaridad, 1, 2));
      this.datosValores.retencion = this.formatCurrency(this.obtenerValor(this.datos.retencion, 1, 2));
      this.datosValores.arp = this.formatCurrency(this.obtenerValor(this.datos.arp, 1, 2));
      this.datosValores.comfaboy = this.formatCurrency(this.obtenerValor(this.datos.comfaboy, 1, 2));
    },
    crearSalario() {
      return apiService.newSalario(this.datos).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Successful', detail: 'Salario Creado Correctamente', life: 3000});
          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 3000});
          }
      );
    },
  },
  mounted() {
    if (this.editar) {
      this.datos=this.selected
      this.calcularValores()
    }
  }
}
</script>



import {auth} from '@/utils/firebase'
import firebase from "firebase/compat/app";

class AuthService {

    login(user, password) {
        return auth.signInWithEmailAndPassword(user, password)
            .then((success) => {
                return Promise.resolve(success);
            })
            .catch((error) => {
                let errorMessage;
                if (error.code === 'auth/user-not-found') {
                    errorMessage = 'Usuario no encontrado';
                } else if (error.code === 'auth/wrong-password') {
                    errorMessage = 'Contraseña incorrecta';
                } else if (error.code === 'auth/invalid-email') {
                    errorMessage = 'Correo electrónico inválido';
                } else if (error.code === 'auth/invalid-credential') {
                    errorMessage = 'Contraseña invalida, por favor valida e intenta nuevamente';
                } else {
                    errorMessage = error.message;
                }
                return Promise.reject(errorMessage);
            });
    }

    initializeAuthStateListener() {
        return new Promise((resolve, reject) => {
            auth.onAuthStateChanged((user) => {
                if(user) {
                    user.getIdTokenResult().then((success) => {
                        const expirationTimeMilliseconds = new Date(success.expirationTime).getTime();
                        const lastLogin = new Date(user.metadata.lastSignInTime).getTime();
                        let fechaActual = Date.now();
                        if (expirationTimeMilliseconds < fechaActual || lastLogin + (60 * 30 * 1000) < Date.now()) {
                            return reject('Error de Token: Token vencido');
                        } else {
                            resolve()
                        }
                    }).catch((error) => {
                        return reject('Error de Token:' + error.message);
                    });
                }else {
                    reject('Error de Token: Usuario no autenticado');
                }
            });
        });
    }

    loginGoogle() {
        const provider = new firebase.auth.GoogleAuthProvider();
        return auth.signInWithPopup(provider)
    }

    logout() {
        return auth.signOut().then(
            (success) => {
                return Promise.resolve(success);
            }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default new AuthService();
